@import "../../constant.scss";

// Footer styles
.footer {
  background-color: transparent;
  color: $main-color;
  padding: 10px 0;
  text-align: center;

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 20px;

    .footer-logo {
      font-size: 1.5em;
      font-weight: bold;
    }

    .footer-links {
      list-style: none;
      display: flex;
      gap: 10px;

      li {
        a {
          color: $main-color;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
