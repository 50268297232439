// responsive
$mobile-width: 480px; 
$tablet-width: 768px; 
$desktop-width: 1024px; 

$header-line: 6px;
$header-height: 78px;
$main-margin: 16px;

// color
$main-color: #69955b;
$main-light: #E5D9B6;

$text-color: #000;