@import "../../constant.scss";

.title-tool {
    color: $main-color;
    background: #fff;
    border: 1px solid rgba(230, 230, 230, 1);
    display: inline-block;
    margin: 8 0px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5;
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.buttons {
    display: flex;
    margin: 10px 0px;
    justify-content: space-between;
    align-items: center; // Add this line

    .dx-button {
        margin-left: 8px;

        :first-child {
            margin-left: 0px;
        }
    }
}

.editor-container {
    display: flex;
    flex-direction: column;
    .editor {
        flex: 1;
        height: 30vh;
    }

    @media (min-width: 768px) {
        flex-direction: row;
        .editor {
            flex: 2;
            height: 65vh;
        }
    }
}

.data-count {
    margin-left: 20px; // Add this line to add some space between buttons and data count
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    display: flex;
    justify-content: space-between;
}