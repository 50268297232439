@import "../../constant.scss";

.header-line {
    background: $main-color;
    height: $header-line;
}

.header {
    position: relative;
    z-index: 5;
    background: #fff;
    padding: 13px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);

    .content-block {
        height: $header-height;
        max-width: 1020px;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;

        .logo {
            height: 100%;
        }

        .main-menu {
            float: right;
            margin-top: 31px;
        }

        .header-item {
            color: #8b8b8b;
            font-size: 15px;
            display: inline-block;
            margin-right: 25px;
            font-weight: 700;
            position: relative;
            text-transform: uppercase;

            &:last-child {
                margin-right: 0;
            }
        }

        .active {
            color: $main-color;
        }

        .menu {
            float: right;
            .icon-menu {
                margin-top: 20px;
                display: none;
            }
        }
    }

    @media screen and (max-width: $tablet-width) {
        .content-block {
            height: auto;
            max-width: 100%;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            text-align: center;
            margin: 0 10px;

            .logo {
                height: 50px;
            }

            .main-menu {
                display: none;
                float: none;
                position: fixed;
                top: 50;
                right: 0;
                background-color: #fff;
                width: 200px;
                flex-wrap: wrap;

                .header-item {
                    white-space: wrap;
                    text-align: left;
                    padding: 8px 16px;
                    border: #eee 1px solid;
                    width: 100%;
                }

            }

            .menu {
                .icon-menu {
                    display: block;
                }
            }

            .opened {
                display: block
            }
        }

    }


    @media screen and (max-width: $desktop-width) {
        .content-block {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

}