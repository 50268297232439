@import "../../constant.scss";

.home-page {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px;
}

.group {
    border-bottom: 1px solid rgba(230, 230, 230, 1);
    margin-bottom: 16px;
    padding-bottom: 16px;

    .header-group {
        color: $main-color;
        background: #fff;
        border: 1px solid rgba(230, 230, 230, 1);
        display: inline-block;
        margin: 0 2px;
        padding: 5px 10px;
        margin: 0px 8px;
        font-weight: 700;
        font-size: 18px;
        border-radius: 5;
        position: sticky;
        top: 0;
    }

    .products {
        width: 100%;

        .product-item {
            width: calc(100%/3);
            float: left;

            a,
            a:visited,
            a:active {
                text-decoration: none;
                color: #000;
            }

            @media screen and (max-width: $desktop-width) {
                width: calc(100%/2);
            }

            @media screen and (max-width: $tablet-width) {
                width: 100%;
            }

            .product-content {
                background-color: #fff;
                margin: 8px;
                border-radius: 8px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
                height: 80px;

                &:hover {
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
                    cursor: pointer;
                }

                .icon {
                    background-color: $main-color;
                    color: $main-light;
                    border-radius: 8px;
                    font-size: 32px;
                    padding: 24px;
                    float: left;
                }

                .icon-red {
                    background-color: #D2001A;
                }

                .icon-blue {
                    background-color: #0008C1;
                }

                .icon-violet {
                    background-color: #8758FF;
                }

                .icon-black {
                    background-color: #251B37;
                }

                .icon-brown {
                    background-color: #3C2317;
                }

                .content {
                    float: left;
                    height: 64px;
                    width: calc(100% - 80px);

                    .title {
                        margin-top: 8px;
                        padding: 0px 8px;
                    }

                    .description {
                        font-size: 0.8rem;
                        margin-top: 6px;
                        padding: 0px 8px;
                    }
                }
            }
        }
    }

    .text {
        color: $main-color;
    }

    .text-red {
        color: #D2001A;
    }

    .text-blue {
        color: #0008C1;
    }

    .text-violet {
        color: #8758FF;
    }

    .text-black {
        color: #251B37;
    }

    .text-brown {
        color: #3C2317;
    }

}