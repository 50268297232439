@import "./constant.scss";

@font-face {
    font-family: ProximaNovaSoft;
    src: url("./assets/font/ProximaNovaSoft-Regular.otf") format("opentype");
}

html,
body {
    padding: 0;
    margin: 0;
    background: url("./assets/img/bg2.png");
    font-family: 'Montserrat', sans-serif;
    font-family: ProximaNovaSoft, sans-serif;
    font-family: "proxima-nova-soft",sans-serif;
    &::-webkit-scrollbar {
        display: none;
    }
}

.main-container {
    overflow-y: scroll;
    height: calc(100vh - ($header-height + $header-line + $main-margin + 80px));
    .main-content {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: $main-margin;
        padding: 8px;
    }
}